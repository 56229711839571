import { storyblokEditable } from '@storyblok/react'
import { motion, useScroll, useTransform } from 'framer-motion'
import NextImage from 'next/image'
import { useRef } from 'react'

import { Button } from 'common/UI'
import { getValidIcon } from 'common/UI/Icon'
import { ClinicHeroStoryblok } from 'common/types'
import {
  getAnchorFromCmsLink,
  getImageAttributes,
  textByLine,
} from 'common/utils/content'

type ClinicHeroProps = {
  block: ClinicHeroStoryblok
}

export const ClinicHero = ({ block }: ClinicHeroProps) => {
  const {
    headline,
    address_title,
    address,
    see_map,
    open_hours_title,
    open_hours,
    ctas,
    images,
  } = block

  const containerRef = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end start'],
  })

  const topTranslateY = useTransform(scrollYProgress, [0, 1], [50, -25])
  const bottomTranslateY = useTransform(scrollYProgress, [0, 1], [-25, 50])

  const mobileTranslateX = useTransform(scrollYProgress, [0, 1], [0, -110])

  const rectangularImageStyles =
    'hidden relative w-[305px] h-[420px] rounded-tr-[48px]  rounded-bl-[48px] overflow-hidden flex-shrink-0 lg:block'

  const mobileRectangularImageStyles =
    'block relative w-[290px] h-[205px] rounded-tr-12 rounded-bl-12 overflow-hidden flex-shrink-0 lg:hidden'

  return (
    <section
      ref={containerRef}
      className="w-full max-w-screen-desktop overflow-hidden mx-auto"
      {...storyblokEditable(block)}
    >
      <div className="relative flex flex-col items-start w-full py-8 px-4 z-10 md:p-8 lg:flex-row lg:justify-between lg:py-0 lg:px-20 lg:max-h-[636px]">
        <div className="flex flex-col lg:pt-20 lg:max-w-[621px] lg:flex-shrink-0">
          {headline && (
            <h1 className="pb-10 text-title-card lg:text-display font-regular">
              {headline}
            </h1>
          )}

          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-5 max-w-[355px]">
              <div className="space-y-1">
                {address_title && (
                  <p className="opacity-60 text-eighteen font-regular">
                    {address_title}
                  </p>
                )}

                {address && (
                  <>
                    {textByLine(address, (part) => (
                      <p className="text-eighteen">{part}</p>
                    ))}
                  </>
                )}
              </div>

              {see_map &&
                see_map[0]?.link &&
                (() => {
                  const { href, target, rel } = getAnchorFromCmsLink(
                    see_map[0].link
                  )

                  return (
                    <a
                      href={href}
                      target={target}
                      rel={rel}
                      className="underline text-foreground w-fit"
                    >
                      {see_map[0].label}
                    </a>
                  )
                })()}

              <div className="space-y-1">
                {open_hours_title && (
                  <p className=" opacity-60 text-eighteen font-regular">
                    {open_hours_title}
                  </p>
                )}

                {open_hours && (
                  <>
                    {textByLine(open_hours, (part) => (
                      <p className="text-eighteen">{part}</p>
                    ))}
                  </>
                )}
              </div>
            </div>

            {ctas?.length && (
              <div className="flex gap-3">
                {ctas.map((cta, i) => {
                  const typedIcon = getValidIcon(cta.icon)
                  const { href, target, rel } = getAnchorFromCmsLink(cta.link)

                  return (
                    <Button
                      as="a"
                      key={i}
                      rel={rel}
                      href={href}
                      target={target}
                      icon={typedIcon}
                      alternativeText={cta.alternative_text}
                      variant={i === 0 ? 'secondary' : 'primary'}
                    >
                      {cta.label}
                    </Button>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        <div className="relative flex gap-5">
          {/* Mobile elements */}
          <motion.div
            className="flex gap-5 pt-5"
            style={{ x: mobileTranslateX }}
          >
            {images?.[0] &&
              (() => {
                const { alt, src } = getImageAttributes(images[0])

                return (
                  <div
                    {...storyblokEditable(images[0])}
                    className={mobileRectangularImageStyles}
                  >
                    <NextImage
                      fill
                      priority
                      src={src}
                      alt={alt}
                      className="pointer-events-none object-cover h-full"
                    />
                  </div>
                )
              })()}

            {/* Gradient */}
            <span className="w-[202px] h-[205px] rounded-5 lg:hidden bg-[linear-gradient(128deg,hsl(var(--twc-background-secondary-lgbtqia)/1)_-51.44%,hsl(var(--twc-background-secondary-donation)/1)_100%)]" />

            {images?.slice(1).map((image, i) => {
              const { alt, src } = getImageAttributes(image)

              return (
                <div key={i} className={mobileRectangularImageStyles}>
                  <NextImage
                    fill
                    priority
                    sizes="300"
                    src={src}
                    alt={alt}
                    className="pointer-events-none object-cover h-full"
                  />
                </div>
              )
            })}
          </motion.div>

          {/* Desktop elements */}
          <motion.div
            className="flex flex-col gap-5"
            style={{ y: topTranslateY }}
          >
            {images?.slice(0, 2).map((image, i) => {
              const { alt, src } = getImageAttributes(image)

              return (
                <div key={i} className={rectangularImageStyles}>
                  <NextImage
                    fill
                    priority
                    src={src}
                    alt={alt}
                    sizes={i === 0 ? undefined : '300'}
                    className="pointer-events-none object-cover h-full"
                  />
                </div>
              )
            })}
          </motion.div>

          <motion.div
            className="flex flex-col gap-5"
            style={{ y: bottomTranslateY }}
          >
            {/* Gradient */}
            <span className="w-[305pxpx] h-[220px] rounded-5 hidden lg:block bg-[linear-gradient(128deg,hsl(var(--twc-background-secondary-lgbtqia)/1)_-51.44%,hsl(var(--twc-background-secondary-donation)/1)_100%)]" />

            {images?.[2] &&
              (() => {
                const { alt, src } = getImageAttributes(images[2])

                return (
                  <div className={rectangularImageStyles}>
                    <NextImage
                      fill
                      priority
                      sizes="300"
                      src={src}
                      alt={alt}
                      className="pointer-events-none object-cover h-full"
                    />
                  </div>
                )
              })()}
          </motion.div>
        </div>
      </div>
    </section>
  )
}
